@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

*{
    margin: 0;
    padding: 0;
    font-family: 'Inter', sans-serif;
}

body {
    background: #101213;
    background-repeat: no-repeat;
    background-attachment: fixed;
    color: white;
}

.partners{
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 100px;
    margin-bottom: 100px;
}

.title{
    font-size: 40px;
    font-weight: 600;
}

.subtitle{
    color: #727272;
    font-size: 14px;
    margin-top: 10px;
    font-weight: 500;
}

.partners-div {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin-top: 30px;
}

.partner {
    align-items: center;
    background-color: #1a1c1e;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    margin: 10px 0;
    min-width: 45%;
    padding: 15px;
    border: 2px solid #222426;
}

.avatar {
    border-radius: 50%;
    height: 80px;
    margin-right: 20px;
    width: 80px;
}

.user{
    display: flex;
}

.userbar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 20px;
}

.name {
    font-weight: 600;
    font-size: 20px;
    margin: 0;
}

.discordIcon {
    align-items: center;
    display: flex;
    height: 30px;
    justify-content: center;
    margin-right: 20px;
    width: 30px;
}

.become-partner{
    padding: 40px;
    background-color: #1a1c1e;
    border: 2px solid #222426;
    border-radius: 10px;
    margin-top: 50px;
    text-align: center;
}

.small_title{
    font-size: 20px;
    font-weight: 600;
}

.btn-partner{
    padding: 10px 30px 10px 30px;
    background-color: #2db0fe;
    border: none;
    border-radius: 100px;
    color: white;
    font-weight: 500;
    margin-left: 10px;
    font-size: 16px;
    cursor: pointer;
    transition: 0.2s all ease-in;
    margin-top: 20px;
}

.btn-partner:hover{
    transition: 0.2s;
    background-color: #24aae0;
}

@media (max-width: 1000px) {

    .partners{
        width: 85%;
        margin-top: 50px;
    }

    .name{
        font-size: 14px;
    }
    .partner {
        min-width: 100%;
    }
}