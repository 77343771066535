@import url('https://fonts.googleapis.com/css2?family=Bruno+Ace+SC&family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

*{
    margin: 0;
    padding: 0;
    text-decoration: none;
}

html {
    height: 100%;
}

body {
    min-height: 100%;
    display: flex;
    flex-direction: column;
}

.footer{
    width: 100%;
    margin-top: auto;
    height: 310px;
    background-color: #1a1c1e;
    padding: 60px;
    box-sizing: border-box;
}

.footer-flex{
    display: flex;
}

.logo-footer{
    width: 100px;
}

.footer-side{
    width: 33.333%;
}

.company-shortdesc{
    width: 70%;
    color: #D1D5DB;
    margin-top: 5px;
    font-weight: 500;
    font-size: 14px;
}

.line-footer{
    background-color: #222426;
    width: 100%;
    height: 2px;
    margin-top: 50px;
}

.footer-cr{
    font-size: 14px;
    color: #D1D5DB;
    font-weight: 500;
    margin-top: 30px;
}

.footer-title{
    color: white;
    font-size: 18px;
    font-weight: 500;
}

.footer-section{
    color: #D1D5DB;
    display: block;
    margin-top: 10px;
    font-weight: 500;
    font-size: 14px;
    transition: 0.3s all ease-in-out;
}

.footer-section:hover{
    transition: 0.3s;
    color: white;
}

@media (max-width: 1000px) {

    .footer-flex{
        display: block;
    }

    .footer{
        height: auto;
    }

    .footer-side{
        width: 100%;
        margin-top: 20px;
    }

    .company-shortdesc{
        width: 100%;
    }

}