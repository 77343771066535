@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

*{
    margin: 0;
    padding: 0;
    font-family: 'Inter', sans-serif;
}

body {
    background: #101213;
    background-repeat: no-repeat;
    background-attachment: fixed;
    color: white;
}

.company{
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 100px;
    margin-bottom: 100px;
}

.title{
    font-size: 40px;
    font-weight: 600;
}

.subtitle{
    color: #727272;
    font-size: 14px;
    margin-top: 10px;
    font-weight: 500;
}

.grid-team{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

.imageDiv {
    width: 100%;
    text-align: center;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    overflow: hidden;
    position: relative;
    z-index: 2;
}

.imageDiv::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-size: cover;
    filter: blur(50px);
    transform: scale(3);
    z-index: -1;
    background: var(--background);
}

.userImage {
    border-radius: 50%;
    width: 90px;
    height: 90px;
    margin-top: 10px;
    margin-bottom: 10px;
    object-fit: cover;

}

.team-user {
    width: 30%;
    margin: 0 auto;
    height: 235px;
    margin-top: 30px;
    border-radius: 10px;
    background-color: #1a1c1e;
}

.user-section{
    padding: 20px;
}

.user-name{
    font-size: 16px;
    font-weight: 500;
}

.user-title{
    margin-top: 5px;
    color: #727272;
    font-size: 14px;
    font-weight: 500;
}

.user-promote{
    margin-right: 10px;
    font-size: 20px;
    color: #D1D5DB;
    height: 20px;
    width: 20px;
    cursor: pointer;
    margin-top: 20px;
    transition: 0.3s all ease-in-out;
}

.user-promote:hover{
    transition: 0.3s;
    color: white;
}

.join-discord{
    padding: 40px;
    background-color: #1a1c1e;
    border: 2px solid #222426;
    border-radius: 10px;
    margin-top: 50px;
    text-align: center;
}

.small_title{
    font-size: 20px;
    font-weight: 600;
}

.btn-discord{
    padding: 10px 30px 10px 30px;
    background-color: #2db0fe;
    border: none;
    border-radius: 100px;
    color: white;
    font-weight: 500;
    margin-left: 10px;
    font-size: 16px;
    cursor: pointer;
    transition: 0.2s all ease-in;
    margin-top: 20px;
}

.btn-discord:hover{
    transition: 0.2s;
    background-color: #24aae0;
}

.text-about{
    color: #727272;
    width: 100%;
    font-weight: 500;
    margin-top: 20px;
}

.bold{
    font-weight: bold;
}

.flex-about{
    display: flex;
}

.side-about{
    flex: 50%;
    max-width: 50%;
}

.center-about{
    display: flex;
    align-items: center;
    justify-content: center;
}

.logo-about{
    width: 40%;
}

.link-about{
    font-weight: 600;
    color: white;
    transition: 0.3s all ease-in-out;
}

.link-about:hover{
    transition: 0.3s;
    color: #D1D5DB;
}

.div-hardware{
    margin-top: 50px;
}

.top-terminal{
    background-color: #222222;
    width: 100%;
    height: 40px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
}

.terminal{
    background-color: #300a24;
    width: 100%;
    border-radius: 10px;
    margin-top: 30px;
}

.text-part{
    padding: 10px;
}

@media (max-width: 1000px) {

    .company {
        width: 85%;
        margin-top: 50px;
    }

    .grid-team {
        display: block;
    }

    .team-user {
        width: 100%;
    }

    .flex-about {
        display: block;
    }

    .side-about {
        max-width: 100%;
    }

    .center-about{
        display: none;
    }

    .terminal{
        font-size: 12px;
        overflow: hidden;
    }
}