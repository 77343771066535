@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

*{
    margin: 0;
    padding: 0;
    font-family: 'Inter', sans-serif;
}

body {
    background: #101213;
    background-repeat: no-repeat;
    background-attachment: fixed;
    color: white;
}

.first{
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}

.title-big{
    font-size: 50px;
    width: 50%;
    font-weight: bold;
    margin: 0 auto;
    margin-top: 70px;
}

.short-description{
    font-size: 16px;
    color: #727272;
    font-weight: 500;
    width: 50%;
    margin: 0 auto;
    margin-top: 20px;
}

.select-service{
    padding: 20px 40px 20px 30px;
    background-color: #1a1c1e;
    color: #D1D5DB;
    font-weight: 600;
    border-radius: 100px;
    margin-top: 40px;
    margin-right: 10px;
    border: 2px solid #222426;
    outline: none;
    text-align: center;
    cursor: pointer;
}

.get-started{
    padding: 20px 40px 20px 40px;
    background-color: #2db0fe;
    border: none;
    border-radius: 100px;
    color: white;
    font-weight: 500;
    margin-left: 10px;
    font-size: 14px;
    cursor: pointer;
    transition: 0.2s all ease-in;
}

.get-started:hover{
    transition: 0.2s;
    background-color: #24aae0;
}

.center{
    text-align: center;
}

.content{
    display: flex;
    align-items: center;
    justify-content: center;
}

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: url(../images/caret.png) 94% / 3% no-repeat #eee;
}

.laptops{
    width: 65%;
    margin-top: 65px;
}

.second{
    background-color: #1a1c1e;
    height: 700px;
    margin-top: -5px;
    z-index: 99;
}

.second-div{
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
}

.flex-side{
    flex: 50%;
    max-width: 50%;
    padding: 50px;
    margin-top: 50px;
}

.title-second{
    font-size: 50px;
    width: 80%;
    font-weight: 600;
    letter-spacing: 0.15rem;
}

.description-second{
    font-size: 16px;
    color: #727272;
    font-weight: 500;
    width: 80%;
    margin-top: 20px;
}

.grid-layout{
    display: grid;
    grid-template-columns: 50% 50%;
    gap: 50px;
}

.number-section{
    background-color: #222426;
    width: 54px;
    height: 54px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    border-radius: 100px;
}

.title-section{
    font-size: 24px;
    font-weight: 600;
    margin-top: 20px;
}

.description-section{
    width: 80%;
    color: #727272;
    margin-top: 10px;
}

.small-title{
    font-size: 26px;
    color: white;
    font-weight: 600;
    text-align: center;
    margin-top: 120px;
}

.services-flex{
    display: flex;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    margin-top: 50px;
    gap: 50px;
}

.section-service{
    flex: 33.333%;
    max-width: 33.333%;
    height: 500px;
    border: 2px solid #1a1c1e;
    border-radius: 10px;
    padding: 30px;
    box-sizing: border-box;
    text-align: left;
    transition: 0.3s all ease-in-out;
}

.section-service:hover{
    transition: 0.3s;
    margin-top: -5px;
}

.small-description{
    font-size: 16px;
    color: #727272;
    font-weight: 500;
    text-align: center;
    margin: 0 auto;
    margin-top: 10px;
}

.section-title{
    font-size: 20px;
    font-weight: 600;
    color: #D1D5DB;
}

.section-price{
    font-size: 40px;
    margin-top: 20px;
    color: #2db0fe;
    font-weight: bold;
}

.white{
    color: white;
}

.section-monthly{
    font-size: 18px;
    color: #D1D5DB;
    font-weight: 500;
}

.features{
    margin-top: 30px;
}

.checkmark{
    margin-top: 15px;
    color: #0b560b;
}

.section-feature{
    font-weight: 500;
    color: #D1D5DB;
    margin-left: 10px;
}

.section-order{
    background-color: #1a1c1e;
    border: none;
    border-radius: 100px;
    color: #D1D5DB;
    font-weight: 600;
    font-size: 14px;
    cursor: pointer;
    transition: 0.2s all ease-in;
    width: 100%;
    height: 40px;
    margin-top: 100px;
}

.blue{
    background-color: #2db0fe;
}

.section-other{
    color: #2db0fe;
    font-weight: 500;
    font-size: 14px;
    transition: 0.3s all ease-in-out;
    cursor: pointer;
}

.section-other:hover{
    transition: 0.3s;
    color: #118abd;
}

.fourth{
    margin-top: 120px;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
}

.top-20{
    margin-top: 20px;
}

.star{
    background-color: #00b87a26;
    color: #00b87a;
    font-size: 20px;
    width: 40px;
    height: 40px;
    border-radius: 5px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    margin-right: 5px;
}

.line-fade{
    width: 100%;
    border-radius: 100%;
    background: linear-gradient(to left, transparent 0%, #1a1c1e 50%, transparent 100%);
    height: 2px;
}

.scroller{
    margin-top: 90px;
}

.zero{
    padding: 0;
    margin: 0;
}

.flex-section-mover{
    display: flex;
    gap: 20px;
    overflow: hidden;
    height: 470px;
    align-items: center;
}

.mover{
    flex: 50%;
    max-width: 50%;
}

.mover-section{
    background-color: #1a1c1e;
    border-radius: 10px;
    border: 2px solid #222426;
    height: 120px;
    padding: 10px;
    overflow: hidden;
    box-sizing: border-box;
    margin-top: 20px;
    cursor: pointer;
    transition: 0.3s all ease-in-out;
}

.mover-section:hover{
    transition: 0.3s;
    background-color: #222426;
}

.mover-image{
    width: 32px;
    height: 32px;
    border: 2px solid #222426;
    border-radius: 10px;
}

.mover-name{
    color: #D1D5DB;
    font-weight: 600;
    position: relative;
    top: -12px;
    margin-left: 10px;
}

.mover-text{
    color: #727272;
    padding-bottom: 10px;
    font-size: 14px;
    margin-top: 5px;
    font-weight: 500;
}

.left{
    margin-top: -25px;
}

.check-yourself{
    display: block;
    font-size: 14px;
    font-weight: 500;
    color: #00b87a;
    margin-top: 15px;
}

.fifth{
    margin-top: 30px;
}

.center-img{
    display: flex;
    align-items: center;
    flex: 30%;
    max-width: 30%;
    justify-content: center;
}

.large{
    flex: 70%;
    max-width: 70%;
}

.man-thinking{
    width: 80%;
    max-width: 900px;
}

.question-div{
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    background-color: #1a1c1e;
    border-radius: 10px;
    padding: 30px;
    box-sizing: border-box;
    border: 2px solid #222426;
    cursor: pointer;
    user-select: none;
    margin-top: 20px;
}

@media (max-width: 1500px) {
    .question-div{
        width: 100%;
    }
}

.transition {
    transition-property: all !important;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
    transition-duration: .15s !important;
    rotate: 0deg;
}

.question-text{
    color: white;
    font-size: 15px;
    display: inline-block;
    font-weight: 500;
}

.question-answer{
    font-size: 14px;
    color: #D1D5DB;
    font-weight: 500;
    margin-top: 10px;
    display: none;
    width: 90%;
}

.caret-question{
    display: inline-block;
    color: #D1D5DB;
    margin-left: 10px;
}

.active-caret{
    rotate: 180deg;
}

.active{
    display: block;
}

.sixth{
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 160px;
    text-align: center;
    margin-bottom: 150px;
}

.ready-text{
    font-size: 26px;
    font-weight: bold;
    color: white;
}

.lets-host{
    background-color: #2db0fe;
    padding-inline-start: 10px;
    padding-inline-end: 10px;
    padding-top: 5px;
    color: black;
    padding-bottom: 5px;
    border-radius: 10px;
    margin-left: 5px;
}

.text-info{
    margin-top: 15px;
    font-size: 18px;
    font-weight: 500;
    color: #727272;
}

.btn-start{
    background-color: #2db0fe;
    height: 40px;
    padding-right: 30px;
    padding-left: 30px;
    font-weight: 500;
    margin-top: 20px;
    border-radius: 10px;
    outline: none;
    border: none;
    cursor: pointer;
    font-size: 16px;
    color: white;
    transition: 0.3s all ease-in-out;
}

@media (max-width: 1300px) {
    .question-div {
        width: 100%;
    }

    .question-text{
        font-size: 14px;
    }

    .services-flex {
        display: block;
    }

    .section-service {
        max-width: 100%;
        text-align: center;
        margin-top: 20px;
        height: 450px;
    }

    .section-service:hover {
        margin-top: 20px;
    }

    .section-order {
        margin-top: 50px;
    }

    .left {
        margin-top: 0;
    }

    .mover-text {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        width: 150px;
        font-size: 12px;
    }

    .mover-section{
        height: 90px;
    }

    .mover-name{
        font-size: 12px;
    }

    .flex-side{
        padding: 0;
    }

}

@media (max-width: 1000px) {

    .title-big {
        font-size: 30px;
        width: 90%;
    }

    .short-description {
        width: 80%;
    }

    .get-started {
        left: 50%;
        transform: translate(-50%);
        position: absolute;
        display: block;
        margin-top: 20px;
        margin-left: 0;
    }

    .select-service{
        padding: 20px 30px 20px 20px;
    }

    .laptops {
        margin-top: 120px;
        width: 100%;
    }

    .second-div {
        display: block;
        text-align: center;
    }

    .second {
        height: auto;
        padding-bottom: 50px;
    }

    .flex-side {
        max-width: 100%;
    }

    .title-second {
        margin: 0 auto;
        font-size: 30px;
    }

    .grid-layout {
        display: block;
    }

    .section-grid {
        text-align: center;
    }

    .number-section, .description-section {
        margin: 0 auto;
    }

    .number-section {
        margin-top: 30px;
    }

    .description-second {
        margin: 0 auto;
        margin-top: 20px;
    }

    .man-thinking {
        display: none;
    }

    .mover-text {
        width: auto;
        margin: 0 auto;
    }

    .flex-section-mover {
        text-align: center;
        display: block;
    }

    .mover-section{
        margin-left: auto;
        width: 100%;
        margin-right: auto;
    }

    .mover{
        max-width: 100%;
    }
}